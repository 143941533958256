import nandukishore from "../../../images/aboutimages/NanduNandkishore.webp";
import PallavJain from "../../../images/aboutimages/PallavJain.webp";
import SarfarazKhimani from "../../../images/aboutimages/SarfarazKhimani.webp";


export const investorsData = [
  {
    id: 1,
    image: nandukishore,
    alt:
      "Nandu Nandkishore, Former Fortune 100 CEO Ex-Head of Asia, Oceania, Africa for Nestlé & Global CEO for Nestlé Nutrition",
    name: "nandu nandkishore",
    designation: `Former Fortune 100 CEO 
    Ex-Head of Asia, Oceania, Africa for 
    Nestlé & Global CEO for Nestlé Nutrition`,
  },
  {
    id: 2,
    image: PallavJain,
    alt:
      "Pallav Jain, Co-CEO at Performics India MBA from IIM Ahmedabad batch of 2007",
    name: "pallav jain",
    designation: `Co-CEO at Performics India 
    MBA from IIM Ahmedabad batch of 2007`,
  },
  {
    id: 3,
    image: SarfarazKhimani,
    alt:
      "Sarfaraz Khimani, Co-CEO at Performics India MBA from IIM Calcutta batch of 2008",
    name: "sarfaraz khimani",
    designation: `Co-CEO at Performics India 
    MBA from IIM Calcutta batch of 2008`,
  },
];
